import {defineStore} from 'pinia'
import axios from 'axios'

export const useUserStore = defineStore({
    id: 'user',

    state: () => ({
        user:{
            isAuthenticated:false,
            id: null,
            name: null,
            email: null,
            access:null,
            refresh:null,
            defaultWarehousCode:null,
            currentOrderNumber:null,
            activeInternalTransferOrder:0,
            internalTransferOrderListType:null,
        }
    }),

    getters:{

        isAuthenticated: state => state.user.isAuthenticated,
        access: state => state.user.access,
        refresh: state => state.user.refresh,
        id: state => state.user.id,
        name: state => state.user.name,
        email: state => state.user.email,
        defaultWarehousCode: state => state.user.defaultWarehousCode,
        currentOrderNumber: state => state.user.currentOrderNumber,
        activeInternalTransferOrder: state => state.user.activeInternalTransferOrder,
        internalTransferOrderListType: state => state.user.internalTransferOrderListType,
    },

    actions:{
        initStore(){
            //console.log('initStore')

            if (localStorage.getItem('user.access')){
                //console.log('User is authenticated')

                //this.user = JSON.parse(localStorage.getItem('user'))
                this.user.access = localStorage.getItem('user.access')
                this.user.refresh = localStorage.getItem('user.refresh')
                this.user.id = localStorage.getItem('user.id')
                this.user.name = localStorage.getItem('user.name')
                this.user.email = localStorage.getItem('user.email')     
                this.user.defaultWarehousCode = localStorage.getItem('user.defaultWarehousCode')
                this.user.currentOrderNumber = localStorage.getItem('user.currentOrderNumber')         
                this.user.activeInternalTransferOrder = localStorage.getItem('user.activeInternalTransferOrder')
                this.user.internalTransferOrderListType = localStorage.getItem('user.internalTransferOrderListType')
                this.user.isAuthenticated = true

                this.refreshToken()

                //console.log('Initialized user:', this.user)

            }
        },


        setToken(data){
            //console.log('Setting token:', data)

            this.user.access = data.access
            this.user.refresh = data.refresh
            this.user.isAuthenticated = true

            localStorage.setItem('user.access', data.access)
            localStorage.setItem('user.refresh', data.refresh)

        },


        removeToken(){
            console.log('Removing token')

            this.user.access = null
            this.user.refresh = null
            this.user.isAuthenticated = false
            this.user.id = null
            this.user.name = null
            this.user.email = null
            this.user.defaultWarehousCode = null
            this.user.currentOrderNumber = null
            this.user.activeInternalTransferOrder = 0
            this.user.internalTransferOrderListType = null
            
            //localStorage.removeItem('user.access')
            //localStorage.clear()
            localStorage.setItem('user.access', '')
            localStorage.setItem('user.refresh', '')
            localStorage.setItem('user.id', '')
            localStorage.setItem('user.name', '')
            localStorage.setItem('user.email', '')
            localStorage.setItem('user.defaultWarehousCode', '')
            localStorage.setItem('user.currentOrderNumber', '')
            localStorage.setItem('user.activeInternalTransferOrder', 0)
            localStorage.setItem('user.internalTransferOrderListType', '')

        },


        setUserInfo(user){
            //console.log('Setting user info:', user)

            this.user.id = user.id
            this.user.name = user.name
            this.user.email = user.email
            this.user.defaultWarehousCode = user.default_warehouse_code
            this.user.currentOrderNumber = null
            this.user.activeInternalTransferOrder = 0
            this.user.internalTransferOrderListType = null
            
            localStorage.setItem('user.id', user.id)
            localStorage.setItem('user.name', user.name)
            localStorage.setItem('user.email', user.email)
            localStorage.setItem('user.defaultWarehousCode', user.defaultWarehousCode)
            localStorage.setItem('user.currentOrderNumber', null)
            localStorage.setItem('user.activeInternalTransferOrder', 0)
            localStorage.setItem('user.internalTransferOrderListType', null)

            console.log('User info set:', this.user)
        },
            

        async refreshToken(){
            console.log('Refreshing token')
            console.log('defaultWarehousCode:', this.user.defaultWarehousCode)
            if(this.user.defaultWarehouseCode === undefined || this.user.defaultWarehouseCode === null){

                try {
                    const response = await axios.get('/warehouse/api/user_warehouse/', { 
                        params: { 
                            user: this.user.id, 
                        },
                    });
                    

                    //userSetting.setting.curentWarehouse = response.data[0];
                    this.user.defaultWarehousCode = response.data[0]
                    localStorage.setItem('user.defaultWarehousCode', response.data[0])

                    console.log('User warehouse data 0:', this.user.defaultWarehousCode);

                } catch (error) {
                    console.error('There was an error fetching the user warehouse data:', error);
                }
            }

            axios.post('/api/refresh/', {refresh:this.user.refresh})
                .then(response => {
                    this.user.access = response.data.access
                    localStorage.setItem('user.access', response.data.access)
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access

                    console.log('Refreshed token:', response.data)
                    this.setToken(response.data)
                })
                .catch(error => {
                    console.log('Error refreshing token:', error)
                    this.removeToken()
                })
        }
    }

})
