import HomeView from '@/views/publicView/HomeView.vue'

export default [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: { title: 'Home' }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '@/views/publicView/AboutView.vue'),
        meta: { title: 'About' }
      },
      {
        path: '/signup',
        name: 'signup',
        component: () => import('@/views/publicView/SignupView.vue'),
        meta: { title: 'Sign Up' }
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/publicView/LoginView.vue'),
        meta: { title: 'Login' }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/publicView/ContactView.vue'),
        meta: { title: 'Contact' }
      },
      {
        path: '/feed',
        name: 'feed',
        component: () => import('@/views/publicView/FeedView.vue')
      }
  ];
  
