
export default [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/staffView/DashboardView.vue'),
        meta: { requiresAuth: true, title: 'Dashboard' }

      },
      {
        path: '/clockin',
        name: 'clockin',
        component: () => import('@/views/staffView/hr/ClockInView.vue'),
        meta: { requiresAuth: true, title: 'Clockin' }

      },
      {
        path: '/test',
        name: 'test',
        component: () => import('@/views/staffView/test/TestView04.vue'),
        meta: { requiresAuth: true, title: 'Test' }

      },
  ];