<script setup>
import { useToastStore} from '@/stores/toast'

const store = useToastStore()

// return {
//   store
// }

</script>

<template>

    <v-card 
        v-if="store.isVisible" 
        class="white--text" 
        :class="store.classes"
    >
        <p>{{ store.message }}</p>
    </v-card>

</template>