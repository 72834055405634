
export default [
      {
        path: '/internaltransferorder',
        name: 'internaltransferorder',
        component: () => import('@/views/staffView/warehouse/internaltransferorder/InternalTransferOrder.vue'),
        meta: { requiresAuth: true, title: 'Internal Transfer' }

      },
      {
        path: '/printorder',
        name: 'printorder',
        component: () => import('@/views/staffView/warehouse/PrintOrder.vue'),
        meta: { requiresAuth: true, title: 'Internal Transfer' }

      },
  ];