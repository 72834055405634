import { createRouter, createWebHistory } from 'vue-router'

import PublicRoutes from './publicRoutes';
import StaffRoutes from './staffRoutes';
import WarehouseRoutes from './warehouseRoutes';


const routes = [
  ...PublicRoutes,
  ...StaffRoutes,
  ...WarehouseRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  // for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     const value = localStorage.getItem(key);
  //     console.log(`${key}: ${value}`);
  // }

  const token = localStorage.getItem('user.access');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {

      next('/login');
    } else {
      next(); 
    }
  } else {
    next(); 
  }
});

router.afterEach((to, from) => {
  document.title = to.meta.title || 'Default Title';
});

export default router
