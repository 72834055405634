<!-- MainLayout.vue -->
<template>
    <v-app>
      <PublicNavBar />
      <v-main>
        <slot></slot> 
      </v-main>
    </v-app>
  </template>
  
  <script>
  import PublicNavBar from '@/components/PublicNavBar.vue';
  
  export default ({
    name: 'PublicLayout',
    components: {
      PublicNavBar,
    },
  })
  </script>
  