<template>
    <PublicLayout>
      <v-container>
      <v-row justify="center">
        <v-col class="text-center">
          <h1 class="display-1 custom-title">Solar Data</h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="text-center">
          <v-btn :to="{'name':'about'}" class="btn-custom mx-2">About</v-btn>
          <v-btn :to="{'name':'contact'}" class="btn-custom mx-2">Contact</v-btn>
        </v-col>
      </v-row>
    </v-container>
    </PublicLayout>
</template>

<script>

// Components
import HelloWorld from '@/components/HelloWorld.vue';
import PublicLayout from '@/layouts/PublicLayout.vue';

export default ({
  name: 'HomeView',

  components: {
    HelloWorld,
    PublicLayout,
  },
});
</script>

<style>
.custom-title {
  font-size: 4rem;
}

.btn-custom {
  min-width: 120px; /* Set the minimum width for both buttons */
}
</style>
