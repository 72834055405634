<template>
        <v-app-bar scroll-behavior="elevate" elevation="0">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <router-link :to="{name: 'home'}" style="text-decoration: none; color: inherit;">
                <v-toolbar-title>Solar Data</v-toolbar-title>
            </router-link>
            <v-spacer></v-spacer>
            <v-btn  :to="{'name':'signup'}">Sign Up</v-btn>
            <v-btn  :to="{'name':'login'}">Login</v-btn>
        </v-app-bar>
</template>