<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import { useUserStore } from '@/stores/user';
import Toast from '@/components/Toast.vue';

export default {
  name: 'App',
  setup(){
    const userStore = useUserStore();

    return {
      userStore
    }
  },
  components: {
    Toast,
  },
  beforeCreate(){
    this.userStore.initStore();

    const token = this.userStore.user.access

    if(token){
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      axios.defaults.headers.common['Authorization'] = null;
    }
  }
}
</script>
