import { defineStore } from 'pinia'

export const useToastStore = defineStore({
    id:'toast',

    state: () => ({
        ms:0,
        message:'',
        classes:'',
        isVisible:false
    }),

    actions: {
        showToast({ms, message, classes}){
            this.ms = parseInt(ms)
            this.message = message
            this.classes = classes
            this.isVisible = true

            setTimeout(() => {
                //this.isVisible = false
                this.classes = 'black'
            }, 10)

            setTimeout(() => {
                //this.isVisible = false
                //this.classes = this.classes.replace('black', '')
            }, this.ms - 500)

            setTimeout(() => {
                this.isVisible = false
            }, this.ms)

        }
    }
})